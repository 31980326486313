{
  "name": "enrollhere-dialer",
  "version": "0.17.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "upload-dev": "firebase deploy --only hosting:enrollhere --project enroll-here-dev-dialer",
    "upload-prod": "firebase deploy --only hosting:enrollhere --project enroll-here-dialer",
    "replace-timestamp": "node ./replace.build.js",
    "lint": "ng lint",
    "deploy:prod:temp": " ng build --c production &&  firebase deploy --only hosting:enrollhere-dialer --project enroll-here-dialer --config firebase.temp.json",
    "explore-source-maps": "source-map-explorer dist/**/*.js",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-dialer ./dist/enrollhere-dialer/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-dialer ./dist/enrollhere-dialer/browser"
  },
  "private": true,
  "dependencies": {
    "@angular-eslint/builder": "^19.3.0",
    "@angular/animations": "^19.2.3",
    "@angular/cdk": "^19.2.7",
    "@angular/common": "^19.2.3",
    "@angular/compiler": "^19.2.3",
    "@angular/core": "^19.2.3",
    "@angular/elements": "^19.2.3",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "^19.2.3",
    "@angular/platform-browser": "^19.2.3",
    "@angular/platform-browser-dynamic": "^19.2.3",
    "@angular/router": "^19.2.3",
    "@angular/service-worker": "^19.2.3",
    "@cometchat/chat-uikit-angular": "^4.3.23",
    "@cometchat/uikit-elements": "^4.3.21",
    "@floating-ui/dom": "^1.6.13",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-svg-core": "^6.7.2",
    "@fortawesome/pro-regular-svg-icons": "^6.7.2",
    "@googlemaps/js-api-loader": "^1.16.8",
    "@intercom/messenger-js-sdk": "^0.0.14",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@sentry/angular": "^9.9.0",
    "@sentry/cli": "^2.42.5",
    "@swimlane/ngx-charts": "^22.0.0-alpha.1",
    "@tailwindcss/postcss": "^4.0.17",
    "@tailwindcss/typography": "^0.5.16",
    "@telnyx/webrtc": "^2.22.7",
    "@types/canvas-confetti": "^1.9.0",
    "any-date-parser": "^2.0.3",
    "autoprefixer": "^10.4.21",
    "canvas-confetti": "^1.9.3",
    "d3-shape": "^3.2.0",
    "dompurify": "^3.2.4",
    "dotenv": "^16.4.7",
    "fast-levenshtein": "^3.0.0",
    "jsonrepair": "^3.12.0",
    "jssip": "^3.10.1",
    "libphonenumber-js": "^1.12.6",
    "lottie-web": "^5.12.2",
    "moment-timezone": "^0.5.48",
    "nanoid": "^5.1.5",
    "ngx-cookie-service": "^19.1.2",
    "ngx-editor": "^18.0.0",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-toastr": "^19.0.0",
    "plotly.js": "^3.0.1",
    "plotly.js-dist-min": "^3.0.1",
    "prettier": "^3.5.3",
    "replace-in-file": "^8.3.0",
    "rete": "^2.0.5",
    "rete-angular-plugin": "^2.3.2",
    "rete-area-plugin": "^2.1.3",
    "rete-auto-arrange-plugin": "^2.0.2",
    "rete-connection-path-plugin": "^2.0.4",
    "rete-connection-plugin": "^2.0.5",
    "rete-readonly-plugin": "^2.0.2",
    "rete-render-utils": "^2.0.3",
    "rxjs": "~7.8.2",
    "sip.js": "^0.21.2",
    "socket.io-client": "^4.8.1",
    "ssr-window": "^5.0.0",
    "tailwindcss": "^4.0.17",
    "tslib": "^2.8.1",
    "uuid": "^11.1.0",
    "web-worker": "^1.5.0",
    "write-excel-file": "^2.3.2",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.5",
    "@angular-eslint/eslint-plugin": "^19.3.0",
    "@angular-eslint/eslint-plugin-template": "^19.3.0",
    "@angular/cli": "~19.2.5",
    "@angular/compiler-cli": "^19.2.3",
    "@ngrx/schematics": "^19.0.1",
    "@types/d3-shape": "^3.1.7",
    "@types/fast-levenshtein": "^0.0.4",
    "@types/jasmine": "~5.1.7",
    "@types/plotly.js-dist-min": "^2.3.4",
    "@types/uuid": "^10.0.0",
    "daisyui": "^5.0.9",
    "eslint-config-prettier": "^10.1.1",
    "eslint-plugin-prettier": "^5.2.5",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier-eslint": "^16.3.0",
    "source-map-explorer": "^2.5.3",
    "typescript": "~5.8.2"
  }
}
